import CategoryEssay from "@components/CategoryEssay"
import { Category } from "@modules/Essay/store"
import { Carousel } from "antd"
import cx from "classnames"
import _map from "lodash/map"
import moment from "moment"
import React from "react"

import styles from "./style.module.less"

const categories = [
  {
    id: Category.NUDE,
    name: "Nude",
  },
  {
    id: Category.TOPLESS,
    name: "Topless",
  },
  {
    id: Category.LINGERIE,
    name: "Lingerie",
  },
]

const Banner = ({ essayBanners }) => {
  return (
    <>
      <Carousel className="anonimatta-main-carousel" effect="fade" autoplay>
        {_map(essayBanners, (banner) => {
          const formattedDate = moment(banner.releaseDate).format("DD.MM.YY")

          const categoryName = banner.category
            ? categories.find((category) => category.id === banner.category)
                .name
            : ""

          return (
            <div className="relative" key={banner.id}>
              {banner.type === 1 && (
                <div className="absolute w-100 h-100 flex items-end z-1">
                  <div className="flex carousel-content">
                    <div>
                      <CategoryEssay categoryName={categoryName} />

                      <div
                        className={cx(
                          "white fw6 model-name lh-copy f18 f24-m f24-l"
                        )}
                      >
                        {banner.modelName}
                      </div>
                      <div className={cx("flex flex-row")}>
                        <label className="white normal launch-day lh-copy f12">
                          Lançamento<span>•</span>
                          {formattedDate}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.boxShadow}>
                <img
                  src={`${process.env.S3_IMAGES}/${banner.urlPhoto}`}
                  className={styles.imageSize}
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
            </div>
          )
        })}
      </Carousel>
    </>
  )
}

export default Banner

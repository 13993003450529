import EssayBanner from "@components/EssayBanner"
import HeaderFilterBar from "@components/HeaderFilterBar"
import Loading from "@components/Loading"
import PageCenter from "@components/PageCenter"
import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import ListEssay from "@modules/Essay/Site/components/ListEssay"
import { Category } from "@modules/Essay/store"
import { Col } from "antd"
import _get from "lodash/get"
import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"

import { getResultText } from "../../utils/strings"
import styles from "./style.module.less"

const tabs = {
  nude: Category.NUDE,
  topless: Category.TOPLESS,
  lingerie: Category.LINGERIE,
  all: "",
}

const Essay = () => {
  const [isClient, setIsClient] = useState(false)
  const [filter, setFilter] = useState("")
  const [data, setData] = useState(null)
  const [totalData, setTotalData] = useState()
  const [searchParam, setSearchParam] = useState("")
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 8,
  })
  const [isBottom, setIsBottom] = useState(false)
  const [loading, setLoading] = useState(false)
  const [essayBanners, setEssayBanners] = useState([])
  const [activeTab, setActiveTab] = useState("all")

  const [showResultText, setShowResult] = useState(false)
  const { essay, banners } = useStores()
  const { findAllSite, search } = essay
  const { getAllEssayBannersSite } = banners

  const filters = ["nude", "topless", "lingerie"]

  useEffect(() => {
    handleGetEssayBanners()
    setIsClient(true)
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    if (isBottom && data && data.length) {
      setPagination((prevState) => ({
        ...prevState,
        pageSize: prevState.pageSize + 12,
      }))
    }
  }, [isBottom])

  useEffect(() => {
    if (data && data.length < totalData) {
      handleGetAllPosts()
    }
  }, [pagination])

  useEffect(() => {
    handleGetAllPosts()
  }, [filter, searchParam])

  const handleGetAllPosts = async () => {
    setLoading(true)

    if (searchParam) {
      const res = await search({ searchParam, category: filter }).catch((err) =>
        console.error("Error to make search", err)
      )

      const posts = _get(res, "data", [])
      const totalPosts = _get(res, "total", 0)

      setTotalData(totalPosts)
      setData(posts)
      setLoading(false)

      return
    }

    const response = await findAllSite({
      current: pagination.current,
      pageSize: pagination.pageSize,
      category: filter,
      site: true,
      orderBy: "publishedIn",
    }).catch((err) => console.error("Error to make filter", err))

    const posts = _get(response, "data", [])
    const totalPosts = _get(response, "total", 0)

    setTotalData(totalPosts)
    setData(posts)
    setLoading(false)
    setIsBottom(false)
  }

  const handleGetEssayBanners = async () => {
    const response = await getAllEssayBannersSite()

    setEssayBanners(response.data)
  }

  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop

    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight

    const innerHeight = window.innerHeight + 15

    if (scrollTop + innerHeight >= scrollHeight) {
      setIsBottom(true)
    }
  }

  const handleChangeTab = (tab) => {
    if (tabs[tab] === filter) {
      return
    }

    setPagination({
      current: 1,
      pageSize: 12,
    })
    setData(null)
    setFilter(tabs[tab])
    setActiveTab(tab)
  }

  const handleSearch = ({ searchParam, category }) => {
    setShowResult(!(!searchParam && category === "all"))
    setSearchParam(searchParam)
    if (category) handleChangeTab(category)
  }

  if (!isClient) return null

  return (
    <TranslateWrapper>
      <Helmet>
        <title>Ensaios - Anonimatta</title>
      </Helmet>

      <div className="essay">
        <EssayBanner essayBanners={essayBanners} />
        <PageCenter className={styles.page__center__essay}>
          <HeaderFilterBar
            filters={filters}
            handleChangeTab={handleChangeTab}
            handleSearch={handleSearch}
            handleLoading={setLoading}
            className={"custom-filter-tab-essay"}
            title={"Ensaios"}
            showSearch
          />
          {!loading && showResultText && Boolean(totalData) && (
            <section className={styles.resultLengthContainer}>
              <p>{getResultText(totalData)}</p>
            </section>
          )}

          <Col span={24} className={styles.topEssays}>
            <ListEssay data={data} handleChangeTab={handleChangeTab} />
          </Col>
          {loading ? <Loading /> : null}
        </PageCenter>
      </div>
    </TranslateWrapper>
  )
}

export default Essay
